<template>
  <v-menu bottom right offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list flat>
      <v-list-item-group>
        <v-list-item
          :disabled="checkDisable(item)"
          @click="onSelected(item.action)"
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-title>{{ item.tooltip }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    objectType: {
      type: String,
      default: "Company"
    },
    selectedCB: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedCompany: Object
  },
  data() {
    return {
      items: [
        {
          tooltip: `Edit ${this.objectType} View`,
          action: `Edit${this.objectType}View`
        },
        {
          tooltip: `Add New ${this.objectType}`,
          action: `Create${this.objectType}`,
          needCompany: this.objectType == "Contact"
        },
        {
          tooltip: `Edit Selected ${this.objectType}`,
          action: `Edit${this.objectType}`,
          needAtLeast: 1
        },
        {
          tooltip: `Merge Selected ${this.objectType}`,
          action: `Merge${this.objectType}`,
          needAtLeast: 2
        }
      ]
    };
  },
  methods: {
    checkDisable(e) {
      if (e.needAtLeast) {
        return this.selectedCB.length < e.needAtLeast;
      } else if (e.needCompany) {
        return !this.selectedCompany;
      }

      return false;
    },
    onSelected(e) {
      this.$emit("click", e);
    }
  }
};
</script>
