<template>
  <v-card min-width="1200px" class="text-no-wrap">
    <v-toolbar short>
      <template v-if="campaignId">
        <v-select
          name="projectSelect"
          @change="updateDropdown('PROJECT', projectSelect.active)"
          v-model="projectSelect.active"
          :items="projectSelect.items"
          :loading="!projectSelect.items"
          label="Project"
          hide-details
          outlined
          dense
          class="mr-2"
        >
        </v-select>
        <v-divider vertical class="mx-2" />
      </template>

      <v-select
        name="companyViewFilter"
        @change="updateDropdown('COMPANY', company.viewSelect.active)"
        :disabled="showOnlyContact"
        v-model="company.viewSelect.active"
        :items="company.viewSelect.items"
        :loading="viewSelectLoading"
        label="Company View"
        hide-details
        outlined
        dense
        class="mr-2"
      ></v-select>
      <ToolbarMoreOption
        objectType="Company"
        :selectedCB="selectedCB.company"
        @click="handleAction"
      />

      <v-divider vertical class="mx-2" />

      <v-select
        name="contactViewFilter"
        @change="updateDropdown('CONTACT', contact.viewSelect.active)"
        v-model="contact.viewSelect.active"
        :items="contact.viewSelect.items"
        :loading="viewSelectLoading"
        label="Contact View"
        hide-details
        outlined
        dense
        class="mr-2"
      ></v-select>
      <ToolbarMoreOption
        objectType="Contact"
        :selectedCB="selectedCB.contact"
        :selectedCompany="selectedCompany"
        @click="handleAction"
      />

      <v-divider vertical class="mx-2" />

      <template v-for="(item, i) in actionItem">
        <ActionButton
          :icon="item.icons"
          :key="i"
          :toggle="item.toggle"
          :disabled="
            (item.action == 'CreateContact' && !selectedCompany) ||
              (item.action == 'Export' && !projectId)
          "
          :tooltip="item.tooltip"
          :active-tooltip="item.activeTooltip"
          @action-click="handleAction(item.action)"
        />
      </template>
    </v-toolbar>

    <v-divider />
    <DataList
      ref="dataList"
      :selectedCompany.sync="selectedCompany"
      :companyHeaders="company.headers"
      :contactHeaders="contact.headers"
      :showOnlyContact="showOnlyContact"
      :projectId="this.projectId"
      :campaignId="this.campaignId"
      :classId="this.classId"
      @update:selected="onSelected"
    >
    </DataList>
    <keep-alive>
      <Export
        v-if="this.projectId"
        ref="export"
        :dialog.sync="dialog.export"
        :projectId="this.projectId"
        :campaignId="this.campaignId"
        :contactOnly="showOnlyContact"
      />
    </keep-alive>
    <SearchDialog
      :dialog.sync="dialog.search"
      type="advance"
      :projectId="this.projectId"
      :campaignId="this.campaignId"
      :classId="this.classId"
      @search="doSearch"
    />
    <ViewEdit
      ref="viewEdit"
      :projectId="this.projectId"
      :campaignId="this.campaignId"
      :classId="this.classId"
      @update="fetchHeaderAndDV(false)"
    />
  </v-card>
</template>

<script>
import ActionButton from "@/components/util/ActionButton";
import DataList from "@/components/database/data/DataList";
import ToolbarMoreOption from "@/components/database/data/ToolbarMoreOption";
import SearchDialog from "@/components/database/data/search/SearchDialog";
import ViewEdit from "@/components/database/data/dialog/ViewEdit";
import Export from "@/components/database/data/dialog/Export";

export default {
  components: {
    ActionButton,
    DataList,
    ToolbarMoreOption,
    SearchDialog,
    ViewEdit,
    Export
  },
  props: {
    classId: [String, Number],
    //projectId: [String, Number],
    campaignId: [String, Number]
  },
  data() {
    return {
      projectId: null,
      dialog: {
        search: false,
        export: false
      },
      selectedCB: {
        company: [],
        contact: []
      },
      selectedCompany: undefined,
      showOnlyContact: false,
      viewSelectLoading: false,
      company: {
        headers: [],
        viewSelect: {
          items: [],
          active: null
        }
      },
      contact: {
        headers: [],
        viewSelect: {
          items: [],
          active: null
        }
      },
      projectSelect: {
        items: [
          {
            text: "LNDB All Data",
            value: null
          }
        ],
        active: null
      },
      actionItem: [
        {
          icons: "mdi-reload",
          tooltip: "Get Real-time Data",
          action: "Reload"
        },
        {
          icons: "mdi-account-box-multiple",
          tooltip: "Show Contact Only",
          activeTooltip: "Show Company And Contact",
          action: "ShowOnlyContact",
          toggle: true
        },
        {
          icons: "mdi-account-search",
          tooltip: "Search",
          action: "Search"
        },
        {
          icons: "mdi-file-export",
          tooltip: "Export",
          action: "Export"
        }
      ]
    };
  },
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.reloadAll();
      }
    }
  },
  methods: {
    onSelected(e) {
      this.selectedCB = e;
    },
    reloadAll() {
      if (this.campaignId) {
        this.fetchProjectDD();
      }
      this.fetchHeaderAndDV();
      this.$nextTick(() => {
        this.reloadTable();
      });
    },
    handleAction(action) {
      switch (action) {
        case "ShowOnlyContact":
          this.showOnlyContact = !this.showOnlyContact;
          this.reloadTable();
          break;
        case "Reload":
          this.reloadTable();
          break;
        case "Search":
          this.dialog.search = true;
          break;
        case "CreateCompany":
          this.createNew("COMPANY");
          break;
        case "CreateContact":
          this.createNew("CONTACT");
          break;
        case "EditCompanyView":
          this.editView("COMPANY");
          break;
        case "EditContactView":
          this.editView("CONTACT");
          break;
        case "Export":
          this.dialog.export = true;
          break;
      }
    },
    editView(objectType) {
      this.$refs.viewEdit.open(objectType);
    },
    createNew(objectType) {
      if (objectType == "CONTACT") {
        let boundary = this.selectedCompany.boundary;
        let lrbtn = (boundary.min ? "0" : "1") + (boundary.max ? "0" : "1");
        this.openTMUI(
          this.selectedCompany.COMPANY_OBJECT_ID,
          0,
          { campaignId: this.campaignId, projectId: this.projectId, classId: this.classId },
          { type: "cm", lrbtn: lrbtn }
        );
      } else {
        this.openTMUI(0, 0, {
          campaignId: this.campaignId,
          projectId: this.projectId,
          classId: this.classId
        });
      }
    },
    updateDropdown(type, value) {
      if (type == "PROJECT") {
        //this.$router.push({ params: { id: value } });
        this.projectId = value;
        //this.reloadTable();
      } else {
        this.$axios
          .put("/database/data/set_active_dd", {
            campaignId: this.campaignId,
            projectId: this.projectId,
            classId: this.classId,
            value: value,
            type: type
          })
          .then(() => {
            this.fetchHeaderAndDV(true).then(() => {
              if (type == "CONTACT") {
                if (this.selectedCompany) {
                  this.$refs.dataList.fetchContact();
                }
              } else if (type == "COMPANY") {
                this.$refs.dataList.fetchCompany();
              }
            });
          });
      }
    },
    reloadTable() {
      this.$refs.dataList.reloadTable();
    },
    fetchProjectDD() {
      this.projectSelect.active = this.projectId ?? null;
      //Object.assign(this.projectSelect.items, this.$options.data.call(this).projectSelect.items);
      this.$axios
        .get("/lnp/db/dbview/projectlist", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.projectSelect.items = [...this.projectSelect.items, ...res.data];
        });
    },
    fetchHeaderAndDV(skipDropdown) {
      let apiUrl = this.campaignId ? "/lnp/db/dbview" : "/database/data";
      this.viewSelectLoading = true;
      return this.$axios
        .get(apiUrl + "/header", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId,
            classId: this.classId
          }
        })
        .then((res) => {
          this.company.headers = res.data.company.headers;
          this.contact.headers = res.data.contact.headers;

          if (!skipDropdown) {
            this.company.viewSelect.items = res.data.company.view_list;
            this.company.viewSelect.active = res.data.company.active_view_name;
            this.contact.viewSelect.items = res.data.contact.view_list;
            this.contact.viewSelect.active = res.data.contact.active_view_name;
          }
        })
        .finally(() => {
          this.viewSelectLoading = false;
        });
    },
    doSearch() {
      this.reloadTable();
    }
  }
};
</script>
