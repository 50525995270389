<template>
  <div style="display: content">
    <v-dialog :value="dialog" max-width="1000px" @input="closeDialog" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title> Export </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-form v-model="valid">
              <v-radio-group
                v-for="input in formRadioInput"
                :key="input.key"
                class="mt-0 mb-2"
                :label="input.label"
                row
                v-model="formData[input.key]"
                hide-details
              >
                <v-radio
                  v-for="item in input.items"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>

              <DraggableAutocomplete
                class="mt-5"
                clearable
                label="Fields"
                outlined
                persistent-hint
                hint="Drag the fields to change the order"
                :items="attributes"
                v-model="formData.fields"
                :rules="[(value) => !Array.isArray(value) || value.length > 0 || 'Required']"
              />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn plain @click="openSelection('Load')">Load selection</v-btn>
          <v-btn plain @click="openSelection('Save')">Save selection</v-btn>
          <v-spacer />
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="save" :loading="loading">Export</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="selection.dialog" max-width="400px">
        <v-card>
          <v-card-text>
            <v-container fluid>
              <v-form v-model="selection.valid">
                <v-select
                  return-object
                  v-model="selection.data.name"
                  :items="selection.items"
                  label="Selection"
                  dense
                  outlined
                  hide-details
                  :rules="[(value) => !!value || 'Required']"
                  class="mb-2"
                >
                </v-select>
                <template
                  v-if="
                    selection.mode == 'Save' &&
                      selection.data.name &&
                      selection.data.name.value == 'NEW'
                  "
                >
                  <v-text-field
                    v-model="selection.data.newName"
                    label="Save as"
                    dense
                    hide-details
                    outlined
                  />
                  <v-checkbox
                    v-model="selection.data.isPublic"
                    hide-details
                    label="Set as Public"
                  />
                </template>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="selection.mode == 'Load'" @click="handleSelectionAction('Delete')"
              >Delete</v-btn
            >
            <v-spacer />
            <v-btn plain @click="selection.dialog = false">Cancel</v-btn>
            <v-btn
              plain
              color="primary"
              @click="handleSelectionAction(selection.mode)"
              :loading="selection.loading"
              :disabled="!selection.valid"
            >
              {{ selection.mode }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-snackbar v-model="exportProgress.snackbar" :timeout="-1" style="z-index:100">
      {{ exportProgress.text }}
      <v-progress-linear v-model="exportProgress.progress" color="white"></v-progress-linear>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          color="primary"
          v-if="exportProgress.progress < 100"
          @click="handleExportAction('cancel')"
        >
          Cancel
        </v-btn>
        <v-btn text v-bind="attrs" color="primary" v-else @click="handleExportAction('download')">
          Download
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DraggableAutocomplete from "@/components/util/DraggableAutocomplete";

export default {
  components: {
    DraggableAutocomplete
  },
  props: {
    dialog: { default: false },
    projectId: [String, Number],
    campaignId: [String, Number],
    contactOnly: { default: false }
  },
  data() {
    return {
      timer: undefined,
      exportProgress: {
        progress: 0,
        text: "",
        md5: null,
        snackbar: false
      },
      selection: {
        loading: false,
        dialog: false,
        mode: "Load",
        items: [],
        data: {}
      },
      valid: false,
      loading: false,
      formData: {},
      attributes: [],
      formRadioInput: [
        {
          key: "REPORT_TYPE",
          label: "Export format:",
          items: [
            { text: "CSV", value: "CSV" },
            { text: "Excel", value: "EXCEL" },
            { text: "Tab Delimiter", value: "TAB" }
          ]
        },
        {
          key: "ATTRIBUTE_DISPLAY",
          label: "Export campaign fields as:",
          items: [
            { text: "Attribute Name", value: "ATTR_NAME" },
            { text: "Attribute Label", value: "ATTR_LABEL" },
            { text: "Both", value: "BOTH" }
          ]
        },
        {
          key: "withContactHistory",
          label: "With contact history:",
          items: [
            { text: "No", value: 0 },
            { text: "Yes", value: 1 }
          ]
        },
        {
          key: "tagDelivered",
          label: "Tag export as Leads Delivered:",
          items: [
            { text: "No", value: 0 },
            { text: "Yes", value: 1 }
          ]
        }
      ],
      md5: null
    };
  },
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.setup();
      }
    }
  },
  mounted() {
    //test
    //this.openSelection("Save");
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    handleSelectionAction(action) {
      switch (action) {
        case "Load":
          this.formData.fields = this.selection.data.name.value;
          this.selection.dialog = false;
          break;

        case "Save":
          this.saveSelection(action);
          this.selection.dialog = false;
          break;
        case "Delete":
          this.saveSelection(action);
          break;
      }
    },
    saveSelection(mode) {
      this.selection.loading = true;
      this.$axios
        .put("/lnp/db/dbview/export/save_selection", {
          campaignId: this.campaignId,
          fields: mode == "Delete" ? null : this.formData.fields,
          ...this.selection.data
        })
        .then((res) => {
          this.$store.commit("sendAlert", {
            msg: "Selection " + (res.data.deleted ? "deleted" : "updated"),
            color: "success"
          });
          if (mode == "Delete") {
            this.openSelection();
          }
        })
        .finally(() => {
          this.selection.loading = false;
        });
    },
    openSelection(mode = "Load") {
      //Object.assign(this.selection, this.$options.data.call(this).selection);

      this.selection.dialog = true;
      this.selection.mode = mode;
      this.selection.data.isPublic = false;
      this.selection.data.newName = "";

      this.$axios
        .get("/lnp/db/dbview/export/load_selection", {
          params: {
            campaignId: this.campaignId,
            mode: mode
          }
        })
        .then((res) => {
          this.selection.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    callSave(tagDelivered) {
      this.loading = true;
      this.$axios
        .post("/lnp/db/dbview/export/export", {
          projectId: this.projectId,
          TAG_LEAD_DELIVERED: tagDelivered,
          dbview_compcont: this.contactOnly,
          ...this.formData
        })
        .then((res) => {
          if (res.data.md5) {
            this.md5 = res.data.md5;
            this.handleExportAction("exporting", res.data);
            //this.$emit("update", res.data);
            this.setTimer();
            this.closeDialog();
          } else {
            console.log(res.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.formData.tagDelivered) {
        this.$root
          .$confirm("Export with tag?", "Export will be tagged as Leads Delivered. Continue?", {
            color: "red"
          })
          .then((confirm) => {
            if (confirm) {
              this.callSave(true);
            }
          });
      } else {
        this.callSave(false);
      }
    },
    checkExportProgress() {
      this.$axios
        .get("/lnp/db/dbview/export/check_export", {
          params: {
            md5: this.md5
          }
        })
        .then((res) => {
          this.handleExportAction("exporting", res.data.progress);
          if (res.data.progress < 100) {
            this.setTimer();
          } else {
            let notify = this.sendNotification("Export completed. Click here to donwload.", true);
            let self = this;
            notify.onclick = function() {
              window.focus();
              self.handleExportAction("download");
            };
          }
        });
    },
    setup() {
      //default all radio btn value to first option
      this.formRadioInput.forEach((e) => {
        this.formData[e.key] = e.items[0].value;
      });
      this.loading = true;
      this.$axios
        .get("/lnp/db/dbview/export/get_fields", {
          params: {
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.attributes = res.data.attrs;
          this.formData.fields = res.data.default;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setTimer() {
      let self = this;
      self.timer = setTimeout(function() {
        self.checkExportProgress();
      }, 1000 * 4);
    },
    handleExportAction(action = "cancel", exportInfo = null) {
      if (action == "exporting") {
        if (exportInfo?.md5) {
          // started
          this.exportProgress.text = `Exporting ${exportInfo.count} contacts...`;
          this.exportProgress.snackbar = true;
        } else {
          //update status
          this.exportProgress.progress = exportInfo;
        }
      } else if (action == "download") {
        let domainName = this.$store.getters["auth/getApiHost"]["data_host"];
        let downloadUrl = `${domainName}/common/database/export/download_file.htm?jwt=${this.$store.state.auth.token}&MD5=${this.exportProgress.md5}`;
        window.open(downloadUrl);
      } else if (action == "cancel") {
        if (this.timer) clearTimeout(this.timer);
      }
      if (!exportInfo) {
        Object.assign(this.exportProgress, this.$options.data.call(this).exportProgress);
      }
    }
  },
  destroyed() {
    if (this.timer) clearTimeout(this.timer);
  }
};
</script>
