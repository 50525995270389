<template>
  <div style="display: contents">
    <v-card outlined tile>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-ripple
                @click="onHeaderClicked(item)"
                v-for="(item, i) in headers"
                :key="i"
                class="text-left"
              >
                {{ item.identifier ? "*" : "" }}{{ item.text }}{{ item.name ? "**" : "" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td v-for="(col, x) in item" :key="x">{{ col }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="attrForm.active" max-width="500px">
      <v-card>
        <v-card-text>
          <v-form v-model="valid">
            <div class="py-3">
              <v-radio-group v-model="attrForm.activeType" row hide-details>
                <v-radio label="DB Attribute" value="DB"></v-radio>
                <v-radio label="Create New Campaign" value="NEW"></v-radio>
              </v-radio-group>
            </div>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <template v-if="attrForm.activeType == 'DB'">
                <v-col cols="12">
                  <v-autocomplete
                    return-object
                    @change="onSelectAttr"
                    v-model="selectedAttrId"
                    :items="attrList"
                    label="DB Attribute"
                    dense
                    outlined
                    hide-details
                    :rules="[(value) => !!value || 'Required']"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="attrForm.selectedAttr.objectType">
                  <v-checkbox
                    hide-details
                    :label="
                      `Use this field to identify the ${attrForm.selectedAttr.objectType.toLowerCase()}`
                    "
                    v-model="attrForm.selectedAttr.identifier"
                  ></v-checkbox>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="attrForm.selectedAttr.name"
                    label="Name"
                    :rules="[(value) => !!value || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-radio-group v-model="attrForm.selectedAttr.type" row hide-details>
                    <template #label>
                      <div>Type:</div>
                    </template>
                    <v-radio label="Text" value="T"></v-radio>
                    <v-radio label="List" value="L"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" v-if="attrForm.selectedAttr.type == 'L'">
                  <v-checkbox
                    hide-details
                    label="Multiple Value?"
                    v-model="attrForm.selectedAttr.multiple"
                  ></v-checkbox>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn plain @click="attrForm.active = false">Cancel</v-btn>
          <v-btn plain color="primary" :disabled="!valid" @click="onSelectClicked">Select</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["campaignId", "value", "items"],
  data() {
    return {
      valid: false,
      selectedAttrId: null,
      attrForm: {
        activeType: "DB",
        selectedAttr: {},
        active: false
      },
      attrList: [],
      selectedHeader: null,
      headers: []
    };
  },
  mounted() {
    this.headers = { ...this.value };
    this.fetch();
  },
  methods: {
    onSelectAttr(v) {
      this.selectedAttrId = v;
      for (let [key, value] of Object.entries(v)) {
        this.$set(this.attrForm.selectedAttr, key, value);
      }
    },
    onSelectClicked() {
      for (let [key, value] of Object.entries(this.attrForm.selectedAttr)) {
        this.$set(this.selectedHeader, key, value);
      }

      if (this.attrForm.activeType == "DB") {
        this.selectedHeader.name = null;
      } else {
        this.selectedHeader.text = this.selectedHeader.name;
        this.selectedHeader.value = null;
      }

      this.attrForm.active = false;
      this.$emit("input", this.headers);
    },
    onHeaderClicked(e) {
      this.selectedAttrId = e.value;
      this.selectedHeader = e;
      this.attrForm.active = true;
      // if no value(id) = new attr
      this.attrForm.activeType =
        !this.selectedHeader.value && this.selectedHeader.text ? "NEW" : "DB";
      for (let [key, value] of Object.entries(this.selectedHeader)) {
        this.$set(this.attrForm.selectedAttr, key, value);
      }
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get("/lnp/db/upload/getAttrList", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.attrList = res.data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
th:hover {
  background: #d6d5d5 !important;
  outline: none;
  cursor: pointer;
}

table th {
  border-right: 1px solid #d9d9d9;
}

table th:last-child {
  border-right: none;
}

.v-input--selection-controls {
  margin-top: 0;
}
</style>
