<template>
  <v-dialog v-model="dialog" max-width="900px" @input="closeDialog" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title> Edit {{ typeLabel }} View </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form v-model="valid">
            <v-combobox
              clearable
              v-model="selectedView"
              :items="viewItems"
              hide-selected
              label="Company View"
              dense
              outlined
              persistent-hint
              :hint="hints"
              @change="onViewChange"
              :rules="[(value) => !!value || 'Required']"
            >
            </v-combobox>

            <div class="d-flex mb-5 mt-">
              <div style="margin-right:6px; margin-top:20px">Available for:</div>
              <v-checkbox
                v-for="item in ['Lead', 'Agent']"
                :key="item"
                v-model="selectedAvailFor[item]"
                :label="item"
                class="mr-3"
                hide-details
              ></v-checkbox>
            </div>

            <DraggableAutocomplete
              clearable
              label="Fields"
              outlined
              persistent-hint
              hint="Drag the fields to change the order"
              :items="attributes"
              v-model="selectedAttrs"
              :rules="[(value) => !Array.isArray(value) || value.length > 0 || 'Required']"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn plain @click="closeDialog">Cancel</v-btn>
        <v-btn plain @click="deleteView" :disabled="curViewItemIndex == 0">Delete</v-btn>
        <v-btn plain color="primary" @click="save" :disabled="!valid" :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DraggableAutocomplete from "@/components/util/DraggableAutocomplete";

export default {
  components: {
    DraggableAutocomplete
  },
  props: {
    projectId: [String, Number],
    campaignId: [String, Number],
    ccmsId: [String, Number],
    classId: [String, Number]
  },
  data() {
    return {
      viewType: "COMPANY",
      valid: false,
      selectedAvailFor: { Lead: false, Agent: false },
      selectedView: null,
      selectedAttrs: [],
      attributes: [],
      viewItems: [],
      views: [],
      loading: false,
      dialog: false
    };
  },
  computed: {
    typeLabel() {
      return this.viewType == "COMPANY" ? "Company" : "Contact";
    },
    apiUrl() {
      return this.isLNP ? "/lnp/db/dbview/" : "/database/data/";
    },
    isLNP() {
      return !this.classId;
    },
    curViewItemIndex() {
      return this.viewItems.indexOf(this.selectedView);
    },
    hints() {
      let hints = "";
      if (this.selectedView) {
        if (this.viewItems.includes(this.selectedView)) {
          hints = "Editing existing view";
        } else {
          hints = "New view will be created";
        }
      } else {
        hints = "Select from list to edit existing view or type to create one";
      }
      return hints;
    }
  },
  methods: {
    open(viewType) {
      this.viewType = viewType;
      this.setup();
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    deleteView() {
      this.$root
        .$confirm("Delete", "You are deleting [" + this.selectedView + "] view. Continue?", {
          color: "red"
        })
        .then((confirm) => {
          if (confirm) {
            this.$axios
              .delete(this.apiUrl + "delete_dv", {
                params: {
                  campaignId: this.campaignId,
                  projectId: this.projectId,
                  view_name: this.selectedView,
                  view_type: this.viewType
                }
              })
              .then(() => {
                if (this.curViewItemIndex >= 0) {
                  this.viewItems.splice(this.curViewItemIndex, 1);
                }
                this.selectedView = this.viewItems[0];
                this.$store.commit("sendAlert", {
                  msg: "View has been deleted",
                  color: "success"
                });
                this.$emit("update", this.selectedView);
                this.closeDialog();
              });
          }
        });
    },
    save() {
      this.loading = true;
      this.$axios
        .put("/lnp/db/dbview/save_dv", {
          campaignId: this.campaignId,
          projectId: this.projectId,
          view_name: this.selectedView,
          view_type: this.viewType,
          avail_view: this.selectedAvailFor,
          attrs: this.selectedAttrs
        })
        .then(() => {
          this.$emit("update", this.selectedView);
          this.closeDialog();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onViewChange() {
      let curView = this.views[this.selectedView];
      if (curView) {
        this.selectedAttrs = curView.FIELDS;
        this.selectedAvailFor.Lead = curView.AVAIL_CAMPAIGN_LEAD;
        this.selectedAvailFor.Agent = curView.AVAIL_CAMPAIGN_AGENT;
      } else {
        this.selectedAttrs = [];
        this.selectedAvailFor.Lead = false;
        this.selectedAvailFor.Agent = false;
      }
    },
    setup() {
      this.loading = true;
      this.$axios
        .get("/lnp/db/dbview/load_edit_dv", {
          params: {
            projectId: this.projectId,
            campaignId: this.campaignId,
            type: this.viewType
          }
        })
        .then((res) => {
          this.attributes = res.data.attrs;
          this.views = res.data.views;
          this.viewItems = Object.keys(this.views);
          this.selectedView = res.data.selectedView;
          this.onViewChange();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
